
import type { RadioChangeEvent } from 'antd';
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table, Radio } from "antd";

import React, { useState, useRef, useEffect } from "react";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import type { TabsProps } from 'antd';
import HeaderApp from "../../header-app";
import { Footer } from "antd/es/layout/layout";
import DelegateTop from "../delegate-top";



const RewardTab = () => {

    const [value, setValue] = useState(1);

    const onChange = (e: RadioChangeEvent) => {
    console.log('radio checked', e.target.value);
    setValue(e.target.value);
  };
    
    return (
        <>
            <div className="reward-tab-content">
                <Row gutter={30}>
                    <Col xs={24} sm={6}>
                        <p className="text-white mb-5">Total rewards</p>
                        <div className="flex-left">
                            <span className="text-blue">2000</span>
                            <div className="flex-left">
                                <img width={25} src="../images/helalogo.svg" />
                                <span className="text-white">$Hela</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={6}>
                        <p className="text-white mb-5"><ExclamationCircleOutlined className="text-blue"/> Choose a period time</p>
                        <div className="flex-left">
                            <Radio.Group onChange={onChange} value={value}>
                                <Radio value={1}>07 Days</Radio>
                                <Radio value={2}>30 Days</Radio>
                                <Radio value={3}>120 Days</Radio>
                            </Radio.Group>
                        </div>
                    </Col>
                    <Col xs={24} sm={6}>
                        <div className='p-15 bg-blue border-radius-10'>
                            <p className="text-white mb-5 mt-0">Total receive</p>
                            <p className="text-white mb-5 mt-0">0.00</p>
                            <p className="text-blue mb-0 mt-0">Early unstaking penalty: 10%</p>
                        </div>
                    </Col>
                    <Col xs={24} sm={6}>

                    </Col>
                </Row>
            </div>
        </>
    );
};
export default RewardTab;
