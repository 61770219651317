/* eslint-disable no-restricted-globals */
import { Col, Drawer, Menu } from "antd";
import type { MenuProps } from 'antd';
import React, { useState, useRef, useEffect } from "react";
import { isMobile } from "react-device-detect";
import { Link, useNavigate } from "react-router-dom";
import SwithChain from "../component/ConnectWallet/SwitchChain";
import Web3 from "web3";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import WagmiConnect from "../component/ConnectWallet/wagmi-connect";
import queryString from 'query-string';
import { BaseApi } from "../config/api/BaseApi";
import { notiError, notiSuccess } from "../utils/notication";
import './style.scss';

declare const window: Window &
  typeof globalThis & {
      trustwallet: any;
      ethereum: any;
      bitkeep: any;
      okexchain: any;
      safepalProvider: any;
  };

const HeaderBuyNode = () => {

  const { address, connector, isConnected } = useAccount();
  const { selectedNetworkId } = useWeb3ModalState()
  
  let refCode:any = '';
  let paramUrl:any = queryString.parse(location.search);
  if (paramUrl.pts !== undefined && paramUrl.pts.length > 0) {
      refCode = paramUrl.pts;
  }



  let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : null;

  const navigate = useNavigate();
  const [open, setOpen] = useState(false);

  let web3:any;
  const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
  

  if (connectorId === "io.metamask" || connectorId === "bsc") {
      web3 = new Web3(window.ethereum);
  } else if (connectorId === "com.trustwallet.app") {
      web3 = new Web3(window.trustwallet);
  } else if (connectorId === "com.bitget.web3") {
      web3 = new Web3(window.bitkeep.ethereum);
  } else if (connectorId === "com.okex.wallet") {
      web3 = new Web3(window.okexchain);
  } else {
      web3 = new Web3(window.ethereum);
  }

  let defaut:any = 'stake';
  let path = window.location.pathname;

  if(path == "/claim"){
    defaut = 'claim';
  }
  if(path == "/leaderboard"){
    defaut = 'leaderboard';
  }
  if(path == "/buy-guardian-node"){
    defaut = 'buy-guardian-node';
  }
  if(path == "/delegate"){
    defaut = 'delegate';
  }
  if(path == "/profile"){
    defaut = 'profile';
  }
  if(path == "/faq"){
    defaut = 'faq';
  }
  if(path == "/stake"){
    defaut = 'staking-nft';
  }
  if(path == "/airdrop"){
    defaut = 'airdrop';
  }
  if(path == "/"){
    defaut = 'home';
  }

  const [current, setCurrent] = useState(defaut);
  const items: MenuProps['items'] = [
    {
      label: (
        <a className="text-black" href="/">Home</a>
      ),
      key: 'home',
    },
    {
      label: (
        <a className="text-black" href="/buy-guardian-node">Buy Guardian Node</a>
      ),
      key: 'buy-guardian-node',
    },
    {
      label: (
        <a className="text-black" href="/stake">Mint & Stake</a>
      ),
      key: 'staking-nft',
    },
    
    // {
    //   label: (
    //     <>
    //       {process.env.REACT_APP_ENV == "development" ? (
    //         <a href="/delegate">Delegate <small className="text-lightblue">(Coming soon)</small></a>
    //       ):(
    //         <>Delegate <small className="text-lightblue">(Coming soon)</small></>
    //       )}
    //     </>
    //   ),
    //   key: 'delegate',
    //   disabled:true
    // },
    {
      label: (
        <>
          <a className="text-black" href="/airdrop">Airdrop</a>
        </>
      ),
      key: 'airdrop',
    },
    {
      label: (
        <a className="text-black" href="/faq">FAQs</a>
      ),
      key: 'faq',
    },
    // {
    //   label: (
    //     <a href="#">Blog</a>
    //   ),
    //   key: 'blog',
    // },
    {
      label: (
        <a className="text-black" href="https://docs.helalabs.com/" target="_blank">Docs</a>
      ),
      key: 'docs',
    }
  ];

  const onClickActiveMenu: MenuProps['onClick'] = (e) => {
    setCurrent(e.key);
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
      setOpen(false);
  };

  const message = "Welcome to HeLa Node!";
  const getSignMeta = async ()=>{
    if(signatureMeta == null && address){
      const signature = await web3.eth.personal.sign(message, address);
      if(signature){
        localStorage.setItem('signature', signature);
        window.location.reload();
      }
      
      
    }
  }
  
  useEffect(() => {
    if(address && signatureMeta == null){
      getSignMeta();
    }

  }, [address, signatureMeta]);

  useEffect(() => {
    if(address && window?.ethereum){
      window.ethereum.on('accountsChanged', function () {
        localStorage.removeItem('signature');
        localStorage.removeItem('access_token');
        window.location.reload();
      });
    }
  }, [address]);
  
  return (
    <>
    {isMobile ? (
        <>
          <header className="header header-bg-node">
            <div className="main-header">
              <div className="header-left">
                <div className="icon-bar">
                  <Link to="/"><img src="../../../images/hela-node-logo-b.svg" alt="" /></Link>
                </div>
              </div>
              <div className="select-fiter">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
              <div className="round-connect">
                <div className="icon-bar" onClick={showDrawer}>
                  <img width="40" src="../../../images/bar-m-1.png" alt="" />
                </div>
              </div>
            </div>
          </header>
          <Drawer className="drawer-menu" title="" placement="right" onClose={onClose} open={open}>
              <div className="menu-group">
                  <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="vertical" items={items} />
                  {address && (
                    <>
                      <SwithChain />
                    </>
                  )}
              </div>
          </Drawer>
        </>
      ) : (
        <header className="header header-bg-node">
          <div className="main-header">
            <div className="header-left">
              <div className="box-img">
                <Link to="/"><img src="../../../images/hela-node-logo-b.svg" alt="" /></Link>
              </div>
            </div>
            <div className="header-center">
              <Menu className="header-menu" onClick={onClickActiveMenu} selectedKeys={[current]} mode="horizontal" items={items} />
              
            </div>
            <div className="header-right">
              {address && (
                <>
                  <SwithChain />
                </>
              )}
              
              <div className="round-connect">
                <WagmiConnect open={open} onClose={onClose} />
              </div>
            </div>
          </div>
        </header>
      )}
      
      
    </>
  );
};
export default HeaderBuyNode;
