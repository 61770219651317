
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'



const MyNfts = () => {

    

    const dataSource = [
        {
            key: '1',
            project: 'Hela NFTs',
            totalReward: 1000,
            commision: 32,
            typeName: "Upswap",
            typeIcon: "../images/helalogo.svg",
            numberOfNFTs: 32,
            status: true
        }
    ];

    const columns = [
        {
            title: 'Project',
            dataIndex: 'project',
            key: 'project',
            render: (text:any, record:any) => {    
            return (
                <div className="flex-left">
                    <img width={25} src={record?.typeIcon} />
                    <span>{record?.project}</span>
                </div>
            )}
        },
        {
            title: 'Total Reward',
            dataIndex: 'totalReward',
            key: 'totalReward',
            render: (text:any, record:any) => {    
            return (
                <div className="flex-left">
                    <span className="text-blue">{new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.totalReward)}</span>
                    <div className="flex-left">
                        <img width={25} src={record?.typeIcon} />
                        <span className="text-white">$Hela</span>
                    </div>
                </div>
            )}
        },
        {
            title: 'Commision',
            dataIndex: 'commision',
            key: 'commision',
            render: (text:any, record:any) => {    
            return (
                <div className="text-info">
                    {new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.commision)} %
                </div>
            )}
        },
        {
            title: 'Number of NFTs',
            dataIndex: 'numberOfNFTs',
            key: 'numberOfNFTs',
            render: (text:any, record:any) => {    
            return (
                <div className="text-info">
                    {new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.numberOfNFTs)}
                </div>
            )}
        },
        {
            title: <div className="text-center">Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
                    
            return (
                <div className="text-center">
                    <button disabled={!record?.status} className="btn-white-border">Delegate</button>
                    <button disabled={!record?.status} className="btn-blue-tran">Mint now</button>
                </div>
            )}
        },
        {
            title: <div className='text-gray-light text-center'>Sell NFTs</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
                        
                        <button disabled={!record?.status} className="btn-border-blue text-blue">Sell</button>
                        
					</div>
				)
			}
        },
    ];
  
    return (
        <>
            <p className="text-gray-light mt-0">Total of <span className="text-white font-16">2533</span> Verifiers.</p>
            <div className="">
                <Table className="table-delegates" dataSource={dataSource} columns={columns} />
            </div>
        </>
    );
};
export default MyNfts;
