
import { Col, Tabs, Input, Table, Row, Progress, Empty, Button } from "antd";

import React, { useState, useRef, useEffect } from "react";
import {FileExcelOutlined, InfoCircleOutlined} from '@ant-design/icons'
import axios, { AxiosRequestConfig } from 'axios';
import { BaseApi } from "../../config/api/BaseApi";
import { useAccount } from "wagmi";
import CopyToClipboard from "react-copy-to-clipboard";
import { notiError, notiSuccess } from "../../utils/notication";

const Refferals = () => {

    const { address } = useAccount();
    const [refferal, setRefferal]: any = useState([]);
    const [refferalOrders, setRefferalOrders]: any = useState([]);
    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    const getRefCode = async ()=>{
        let resRef = await BaseApi.getRefCode(address);
        if(resRef.status == 200 && resRef.data.data){
            setRefferal(resRef.data.data);
        }
    }

    const getOrderRefCode = async ()=>{
        let resRef = await BaseApi.getOrderRefCode(address);
        if(resRef.status == 200 && resRef.data.data){
            setRefferalOrders(resRef.data.data);
        }
    }

    useEffect(() => {
        if(address){
            getRefCode();
            getOrderRefCode();
        }
        
    }, [address]);

   

    const onCopy = ()=>{
        notiSuccess('Copied', 3)
    }

    const columns = [
        {
            title: <><div className='text-darkgray text-weight-500 text-center'>User Address</div></>,
            dataIndex: 'user_address',
            key: 'user_address',
            render: (text:any, record:any) => {
                    
            return (
                <div className="text-info text-center">
                    {record?.user_address ? `${record?.user_address.substring(0, 4)}...${record?.user_address.substring(record?.user_address.length - 4)}` : "---"}
                
                </div>
            )}
        },
        {
            title: <><div className='text-darkgray text-weight-500 text-center'>Total Node</div></>,
            dataIndex: 'total_node',
            key: 'total_node',
			render: (text:any, record:any) => {
				
				return (
					<div className='text-regular-blue text-center'>
						{record?.total_node ? new Intl.NumberFormat("ja-JP").format(record?.total_node) : 0}
					</div>
				)
			}
        },
        {
            title: <><div className='text-darkgray text-weight-500 text-center'>Price <small>(ETH)</small></div></>,
            dataIndex: 'total_node',
            key: 'total_node',
			render: (text:any, record:any) => {
				
				return (
					<div className='text-regular-blue text-center'>
						{record?.price ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.price) : 0}
					</div>
				)
			}
        },
        {
            title: <><div className='text-darkgray text-weight-500 text-center'>Total Price <small>(ETH)</small></div></>,
            dataIndex: 'totalPrice',
            key: 'totalPrice',
			render: (text:any, record:any) => {
				
				return (
					<div className='text-regular-blue text-center'>
						{record?.total ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.total) : 0}
					</div>
				)
			}
        },
    ];

    let baseUrl:any = process.env.REACT_APP_BASE_URL;
    let refStr = refferal?.code ? `${baseUrl}?ref=${refferal?.code}` : '';
    let refCodeStr = refferal?.code ? `${refferal?.code}` : '';

    const handleExport = async() => {
    
        let data:any = {
            "address": address,
            "signature": signatureMeta,
            "message": "Welcome to HeLa Node!"
        }

        const headers = {
            'Content-Type': 'application/json',
            // "Authorization": `Bearer ${authToken}`,
        };
        const config: AxiosRequestConfig = {
            method: 'POST', 
            url: `${process.env.REACT_APP_API_URL}api/users/export-ref-orders`,
            data: data,
            responseType: 'arraybuffer', 
            headers
        };
        
        try {
            const response = await axios(config);
            
            const outputFilename = `refferal_${Date.now()}.xlsx`;

            // If you want to download file automatically using link attribute.
            const url = URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', outputFilename);
            document.body.appendChild(link);
            link.click();
            notiSuccess('Successfully');
        } catch (error:any) {
            console.log('error', error);
            notiError('Failed');
        }
    
    };
  
    return (
        <>
            <div className="ref-content">
                <div className="ref-title">Your Referral Link</div>
                <div className="ref-description">Invite friends with your referral code to receive up to 10% rebates from their purchases.</div>
                <Row gutter={40}>
                    <Col xs={24} sm={12}>
                        <div className="mt-20">
                            <Input
                                className='grp-input-discount'
                                style={{ width: '100%'}}
                                placeholder="Discount code"
                                addonAfter={
                                    <CopyToClipboard onCopy={onCopy} text={refStr}>
                                        <button className='btn-lightblue'>Copy Link</button>
                                    </CopyToClipboard>
                                    
                                }
                                value={refStr ? `${refStr.substring(0, 18)}...${refStr.substring(refStr.length - 8)}` : "---"} 
                            />
                        </div>
                    </Col>
                    <Col xs={24} sm={12}>
                        <div className="mt-20">
                            <Input
                                className='grp-input-discount'
                                style={{ width: '100%'}}
                                placeholder="Discount code"
                                addonAfter={
                                    <CopyToClipboard onCopy={onCopy} text={refCodeStr}>
                                        <button className='btn-lightblue'>Copy Code</button>
                                    </CopyToClipboard>
                                    
                                }
                                value={refCodeStr ? `${refCodeStr}` : "---"} 
                            />
                        </div>
                    </Col>
                </Row>
                <div className="ref-total">
                    <Row gutter={30}>
                        <Col xs={24} sm={8}>
                            <div className="total-item bg-lightgray">
                                <div className="text-white weight-400 font-16">Total Earnings <small>(HLUSD)</small></div>
                                <div className="text-white weight-600 font-20 mt-10">{refferal?.total_earning ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 7}).format(refferal?.total_earning) : 0}</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8}>
                            <div className="total-item bg-normalgray">
                                <div className="text-lightblue weight-400 font-16">Commisions <small>(HLUSD)</small></div>
                                <div className="text-white weight-600 font-20 mt-10">{refferal?.total_commission ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 7}).format(refferal?.total_commission) : 0}</div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8}>
                            <div className="total-item bg-darkgray">
                                <div className="text-darkblue weight-400 font-16">Rebates <small>(HLUSD)</small></div>
                                <div className="text-white weight-600 font-20 mt-10">{refferal?.total_rebate ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 7}).format(refferal?.total_rebate) : 0}</div>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="ref-withdraw-money">
                    <Row gutter={30}>
                        <Col xs={24} sm={16}>
                            <div className="flex-left gap-10">
                                <div className="">
                                    <img src="../images/wallet.svg" />
                                </div>
                                <div className="">
                                    <div className="text-white weight-600 font-18">Request Withdraw</div>
                                    <div className="text-lightgray weight-300">Withdraw money securily to your wallet address</div>
                                </div>
                            </div>
                        </Col>
                        <Col xs={24} sm={8}>
                            <div className="text-right">
                                <button disabled className="btn-lightblue border-black">Withdraw Money <small className="text-danger">(Coming soon)</small></button>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="ref-title mt-30 flex-btw">
                    <span>Referred Users</span>

                    <button className="btn-lightblue mb-5" disabled={refferalOrders.length == 0} onClick={()=>handleExport()}><FileExcelOutlined /> Export</button>
                </div>
                <div className="ref-history-table">
                    {refferalOrders && refferalOrders.length > 0 ? (
                        <>
                            <Table className="table-cus" scroll={{y: 300 }} dataSource={refferalOrders} columns={columns} />
                        </>
                    ):(
                        <>
                            <Empty description={<span className="text-white">No data</span>} />
                        </>
                    )}
                </div>
            </div>
        </>
    );
};
export default Refferals;
