
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table, Modal, Button, Select, Empty, Spin, Pagination } from "antd";

import React, { useState, useRef, useEffect } from "react";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import MintNFTAbi from '../../config/abi/MintNFTAbi.json'
import NftNodeStakingAbi from '../../config/abi/NftNodeStakingAbi.json'
import NftNodeAbi from '../../config/abi/NftNodeAbi.json'
import { BaseApi } from "../../config/api/BaseApi";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import { NodeTier } from "../../constants";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import CountdownReloadComponent from "../../utils/countDownReload";
import { convertFromWei } from "../../utils/convertNumber";
import type { TabsProps } from 'antd';
import MintedTab from "./minted-tabs";
import StakedNft from "./staked-tabs";

declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};

const MyNft = () => {

    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const chainId: any = selectedNetworkId;
    const [activeTab , setActiveTab] = useState<any>('');
    const [isModalMint , setIsModalMint] = useState<any>(false);
    const [isModalSuccess , setIsModalSuccess] = useState<any>(false);
    const [isModalStaking , setIsModalStaking] = useState<any>(false);
    const [mintQuantity, setMintQuantity] = useState<any>(0);
    const [myNodes, setMyNodes]: any = useState([]);
    const [myNfts, setMyNfts]: any = useState([]);
    const [nodeMint, setNodeMint]: any = useState(null);
    const [nodeStaking, setNodeStaking]: any = useState(null);
    const [nodes, setNodes]: any = useState([]);
    const [loading, setLoading]: any = useState(false);
    const [loadingDataMyNft, setLoadingDataMyNft]: any = useState(false);
    const [loadingApprove, setLoadingApprove]: any = useState(false);
    const [loadingStaking, setLoadingStaking]: any = useState(false);
    const [loadingUnStaking, setLoadingUnStaking]: any = useState(false);
    const [approveNft, setApproveNft]: any = useState(false);
    const [loadingKeyStaking, setLoadingKeyStaking]: any = useState(false);
    const [totalRow, setTotalRow]: any = useState(0);
    const [totalNftRow, setTotalNftRow]: any = useState(0);
    const [setting, setSetting] = useState<any>(null);
    const [stakingAddress, setStakingAddress] = useState<any>('');
    let nftKeySt:any = localStorage.getItem('nftKey') ? localStorage.getItem('nftKey') : 'minted';
    const [nftKey, setNftKey] = useState<any>(nftKeySt);

    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    

    let web3:any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let mintNftAddress:any = process.env.REACT_APP_MINT_NFT_ADDRESS;
  
    let mintNftContract:any = new web3.eth.Contract(
        MintNFTAbi as unknown as AbiItem,
        mintNftAddress
    );
    let stakeContract:any = new web3.eth.Contract(
        NftNodeStakingAbi as unknown as AbiItem,
        stakingAddress
    );

    const [param, setParam]: any = useState({
            page: 1,
            size: 20
    });
    const [paramNft, setParamNft]: any = useState({
            page: 1,
            size: 20
    });

    const getMyNodes = async () => {
        await BaseApi.getMyNodes(param, address).then(async (res)=>{
            if(res.status == 200 && res.data && res.data.data){
                let poolsData:any = res.data.data.data;
                setTotalRow(res.data.data?.total);
                let arrData:any = [];
                for (let i = 0; i < poolsData.length; i++) {
                    let item:any = poolsData[i];

                    let available_min = Number(item?.total_node) - Number(item?.total_minted_node);

                    item.available_min = available_min;
                    
                    arrData.push(item);
                }

                setMyNodes(arrData);
            }
        }).catch((error)=>{
        console.log(error)
        });
    }
    

    const getSetting = async() => {
        // setLoadingVerify(true);
        try {
            const response = await BaseApi.getSetting();
            
            if(response.status == 200 && response.data && response.data?.data){
               setSetting(response.data?.data);
               setStakingAddress(response.data?.data?.staking_pool_address);
            }else{
                setSetting(null);
            }
        } catch (error) {
            console.log('error', error);
        }
    };


    useEffect(() => {
        getSetting();
        if(address){
            getMyNodes();
        }
        
    }, [address, param]);

    const getListNodes = async () => {
        await BaseApi.getListNodes(param).then((res)=>{
            if(res.status == 200 && res.data && res.data.data){
                let poolsData:any = res.data.data;
                setNodes(poolsData);
            }
        }).catch((error)=>{
        console.log(error)
        });
    }

    useEffect(() => {
        getListNodes();
    }, []);

    const onShowMint = (item:any)=>{
        setNodeMint(item);
        setIsModalMint(true)
    }

    const handleChangeAmount = (e: React.ChangeEvent<HTMLInputElement>) => {
        const inputValue:any = e.target.value;
        const reg = /^-?\d*(\.\d*)?$/;
        if (reg.test(inputValue) || inputValue === '' || inputValue === '-') {
            if(Number(inputValue) > nodeMint?.available_min || Number(inputValue) < 0){
                setMintQuantity(Number(nodeMint?.available_min));
            }else{
                setMintQuantity(Number(inputValue));
            }
            
        }
    };


    let nowDate = new Date();
    let startTime = setting?.mint_license_time ? new Date(setting?.mint_license_time) : '';

    const onChangeTabs = (key: string) => {
        localStorage.setItem('nftKey', key);
        setNftKey(key);
    };

    const itemTabs: TabsProps['items'] = [
    {
        key: 'minted',
        label: 'Minted NFT(s)',
        children: <MintedTab />,
    },
    {
        key: 'staked',
        label: 'Staked NFT(s)',
        children: <StakedNft />,
    }
    ];

    
    const columnMyNode = [
        {
            title: <div className='text-white text-center'>HeLa Node</div>,
            dataIndex: 'node_tier_id',
            key: 'node_tier_id',
            render: (text:any, record:any) => {
				
				return (
					<div className="bg-blue p-5 w-fitcontent m-auto border-5">{NodeTier[record?.node_tier_id]}</div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Quantity</div>,
            dataIndex: 'available_min',
            key: 'available_min',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-blue text-center'>
                        <div className="bg-blue p-5 m-auto w-fitcontent border-5">
                            {record?.available_min ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.available_min) : 0}
                        </div>
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
						<button className="btn-blue-border" disabled={chainId !== 8668 || record?.available_min == 0 || startTime == '' || startTime > nowDate} onClick={()=>onShowMint(record)}> {record?.available_min == 0 ? 'Minted' : 'Mint NFT'}</button>
					</div>
				)
			}
        },
    ];

    const handleMintNFT = async ()=>{
        setLoading(true);

        try {
            
            
            if (mintNftContract === null) {
                notiError('Contract mint invalid', 4)
                setLoading(false)
                setIsModalMint(false);
                return
            }
            
            if (mintQuantity === 0) {
                notiError('Amount must be greater than zero', 4)
                setLoading(false)
                setIsModalMint(false);
                return
            }
            if (signatureMeta === '') {
                notiError('Sign invalid', 4)
                setLoading(false)
                setIsModalMint(false);
                return
            }
            if (mintQuantity <= 0) {
                notiError('Amount node buy invalid', 4)
                setLoading(false)
                setIsModalMint(false);
                return
            }

            let data:any = {
                "wallet": address,
                "message": "Welcome to HeLa Node!",
                "signature": signatureMeta,
                "tier_id": nodeMint?.node_tier_id,
                "totalNode": mintQuantity,
            }

            let mintRs = await BaseApi.userMintLicense(data);
            if(mintRs && mintRs.status == 201 && mintRs.data.data){

                // {
                //     "id": 357,
                //     "address": "0x9f4a688b9d3d8dc581e424e29f5ebd457c603086",
                //     "factory_address": "0xb710ca62105975ad554a597f725a82c1262b7b18",
                //     "owner_address": "0x5ee32ff3a8b50cb496f8c96dc0b7269ff30412d5",
                //     "signature": "0xbd1fff940248e387315622c12875fea13cad7057a229cdc6c7accfd44ce0d1f142005fc199f207c7aefdf476d614b1671df65c2d7d981d9b2a6365c58b3365ec1b",
                //     "tier": 1,
                //     "uri": "https://nft-hela.s3.ap-southeast-1.amazonaws.com/357.json",
                //     "from": "0x0000000000000000000000000000000000000000",
                //     "status": "PENDING",
                //     "minted": false,
                //     "owner_id": 27,
                //     "node_tier_id": 1,
                //     "deleted_at": null,
                //     "created_at": "2024-09-11T08:05:19.007Z",
                //     "updated_at": "2024-09-11T14:06:23.234Z"
                // }

                let mintsData:any = mintRs.data.data;

                let arrData:any = [];

                for (let i = 0; i < mintsData.length; i++) {
                    let item:any = mintsData[i];
                    
                    let newItem:any = [item?.id, address, item?.signature];
                
                    arrData.push(newItem);
                }

                const gasPrice = await web3.eth.getGasPrice();

                let gasEstimate = await mintNftContract.methods
                    .mintNFTs(arrData)
                    .estimateGas({
                        from: address,
                        gasPrice: web3.utils.toHex(String(gasPrice)),
                    });

                await mintNftContract.methods.mintNFTs(arrData)
                .send({
                    from: address,
                    gas: gasEstimate,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                })
                .then(async (resStake: any) => {
                    
                    const interval = setInterval(function () {
                        web3.eth.getTransactionReceipt(
                            resStake?.transactionHash,
                            function (err: any, rec: any) {
                                if (rec) {
                                    
                                    clearInterval(interval);
                                    setIsModalMint(false);
                                    setIsModalSuccess(true);
                                }
                                if (err) {
                                    console.log("err", err);
                                    setLoading(false)
                                    setIsModalMint(false);
                                    notiError('Mint Failed', 3)
                                }
                            }
                        )
                    }, 1000);
                })
                .catch((error: any) => {
                    console.log('stake', error)
                    setIsModalMint(false);
                    if (error) {
                        if (error.code == 4001 && error.message) {
                            notiError(error.message, 5)
                        } else if (error.reason) {
                            notiError(error.reason, 5)
                        } else {
                        if (error.data && error.data.message) {
                            notiError(error.data.message, 5)
                        }
                        }
                    }
                    setLoading(false)
                })
            }
    
            

            

        } catch (error: any) {
            console.log('error', error?.message)
            setIsModalMint(false);
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoading(false)
        }
    }

    
    return (
        <>
            <div className="font-20 mt-30 text-white mb-15">Mint HeLa Guardian Node</div>
            <div className="delegate-total mt-15 mb-15">
                {startTime != '' && startTime > nowDate && (
                    <>
                        <div className="weight-400 text-white text-center">Start In</div>
                        <div className="weight-400 text-white mt-10 mb-15">
                            {setting?.mint_license_time ? CountdownReloadComponent(setting?.mint_license_time) : ''}
                        </div>
                    </>
                )}
                
                <Row gutter={30}>
                    <Col xs={24} sm={6}>
                        <div className="bg-blue p-15 border-5">
                            {/* <div className="text-white text-center">Project Name</div> */}
                            <div className="p-5 border-5">
                                <div className="text-white text-center">
                                    <div className="text-blue d-flex-mid"><img src="../images/helanode.svg" /> HeLa Guardian Node NFT</div>
                                </div>
                                <p className="weight-300 font-14 mt-10 mb-0 text-white text-center">This NFT represents ownership of Guardian Nodes issued on the <br></br> HeLa network.</p>
                            </div>
                        </div>
                    </Col>
                    <Col xs={24} sm={18} className="mt-0-15-mb">
                        
                            <>
                                <div className="tab-over">
                                    <Table scroll={{y: 400, x:700 }} className="table-delegates" bordered={false} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'} dataSource={myNodes} columns={columnMyNode} pagination={false}/>
                                </div>

                                {/* {Number(totalRow) > Number(param?.size) && (
                                    <Pagination
                                        current={param.page}
                                        defaultCurrent={1}
                                        total={totalRow}
                                        pageSize={param.size}
                                        onChange={onChange_Pagi}
                                        showSizeChanger={false}
                                        style={{marginTop: '15px'}}
                                        className="pagiation-custom mt-15"
                                    />
                                )} */}
                            </>
                    
                    </Col>
                </Row>  
                
                
            </div>

            <div className="mt-30">
                <div className="text-white mb-15 font-20">My NFT(s)</div>

                <Tabs className="tabs-delegate" activeKey={nftKey} items={itemTabs} onChange={onChangeTabs} />

            </div>
            <Modal footer={false} className="modal-mint" title={<><span className="text-white weight-500 font-20"></span></>} open={isModalMint} onOk={()=> setIsModalMint(false)} onCancel={()=> setIsModalMint(false)}>
                <div className="text-center text-white font-20 mt-0">Mint NFT</div>
                <div className="text-center text-gray-light font-16 mt-5 border-bottom-10 mb-15">Confirm Information</div>
                <div className="text-white">Wallet: <span className="text-blue">{address ? `${address.substring(0, 4)}...${address.substring(address.length - 4)}` : "---"}</span></div>
                <div className="d-flex-left mt-15">
                    <div className="text-white weight-500">Tier: </div>
                    <div className="text-blue">
                        {NodeTier[nodeMint?.node_tier_id]}
                    </div>
                </div>
                <div className="d-flex-left mt-15">
                    <div className="text-white weight-500">Quantity: </div>
                    <div className="">
                        <div className='d-flex-mid'>
                            <span className='text-blue'>{nodeMint?.available_min}</span>
                            <div className="d-flex-left text-blue"><img src="../images/helanode.svg" /> {nodeMint?.available_min > 1 ? 'Nodes':'Node'}</div>
                        </div>
                    </div>
                </div>
                <div className="mt-15">
                    <div className="text-white weight-500">Amount Mint</div>
                    <div className="mt-10">
                        <Input
                            placeholder="Enter amount"
                            className='input-blue'
                            style={{ width: '100%' }}
                            type="number"
                            value={mintQuantity}
                            max={nodeMint?.available_min}
                            onChange={handleChangeAmount}
                        />
                    </div>
                </div>
                <div className="mt-15 text-blue weight-500">
                    Available: {nodeMint?.available_min} {nodeMint?.available_min > 1 ? 'Nodes':'Node'}
                </div>
                {address ? (
                    <>
                        <div className="d-flex-center gap-10">
                            <button className="btn-blue-border full-width mt-15 weight-500" onClick={handleMintNFT} disabled={mintQuantity == 0 || mintQuantity > nodeMint?.total_node || loading || chainId !== 8668} >Mint now {loading && <Spin size="large"/>}</button>
                            <button className="btn-blue-border bg-darkgray full-width mt-15 weight-500" onClick={()=>setIsModalMint(false)} >Cancel</button>
                        </div>
                    </>
                ):(
                    <WagmiConnect />
                )}
                
                
            </Modal>
            <Modal footer={false} className="modal-mint modal-node-success" title={<><div className="text-green text-center weight-500 font-20">Successfully minted</div></>} open={isModalSuccess} onOk={()=> window.location.reload()} onCancel={()=> window.location.reload()}>
                <div className="text-center text-green font-20 mt-0 mb-15">{NodeTier[nodeMint?.node_tier_id]}!</div>

                <div className="text-center">
                    <div className="imgnode-full">
                        <img src={`https://metadata.helachain.com/node-images/tier-${nodeMint?.node_tier_id ? nodeMint?.node_tier_id : 20}.png`} />
                    </div>
                </div>
                
                <div className="d-flex-center gap-10">
                    <button className="btn-blue-border bg-darkgray full-width mt-15 weight-500" onClick={()=>window.location.reload()} >Close</button>
                </div>
                
                
            </Modal>
            
        </>
    );
};
export default MyNft;
