
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import type { TabsProps } from 'antd';
import HeaderApp from "../../header-app";
import { Footer } from "antd/es/layout/layout";
import DelegateTop from "../delegate-top";
import RewardTab from "./reward-tabs";
import NftsTab from "./nfts-tabs";



const NodeDetail = () => {

    let tab:any = localStorage.getItem('tabSlt');
    const [activeTab , setActiveTab] = useState<any>(tab ? tab : 'rewards');

    const items: TabsProps['items'] = [
        {
            key: 'rewards',
            label: 'Rewards',
            children: <RewardTab />,
        },
        {
            key: 'nfts',
            label: 'NFTs',
            children: <NftsTab />,
        }
        
    ];

    const onChange = (key: string) => {
        localStorage.setItem('tabSlt', key);
        setActiveTab(key);
    };

    
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="delegate-page">
                        <DelegateTop />
                        <div className="mt-15 reward-delegate">
                            <Tabs className="tabs-delegate" activeKey={activeTab} items={items} onChange={onChange} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default NodeDetail;
