
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { TabsProps } from 'antd';
import GuardianNodes from "./guardiant-node";
import MyNodes from "./my-node";
import MyNfts from "./my-nfts";
import DelegateTop from "./delegate-top";


const Delegate = () => {

    let tab:any = localStorage.getItem('tabSlt');
    const [activeTab , setActiveTab] = useState<any>(tab ? tab : 'guardianNodes');
    // const [myNodes, setMyNodes]: any = useState([]);

    const onChange = (key: string) => {
        localStorage.setItem('tabSlt', key);
        setActiveTab(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'guardianNodes',
            label: 'Guardian Nodes',
            children: <GuardianNodes />,
        },
        {
            key: 'my-nodes',
            label: 'My Nodes',
            children: <MyNodes />,
        },
        {
            key: 'my-nfts',
            label: 'My NFTs',
            children: <MyNfts />,
        },
        
    ];

    const myNodes = [
        {
            key: '1',
            project: 'Hela NFTs',
            totalStaked: 2000000,
            totalReward: 1000,
            commision: 32,
            typeName: "Upswap",
            typeIcon: "../images/helalogo.svg",
            airdrop: 1000,
            status: true
        }
    ];

    const columnMyNode = [
        {
            title: <div className='text-white'>Project Name</div>,
            dataIndex: 'project',
            key: 'project',
            render: (text:any, record:any) => {    
            return (
                <div className="flex-left">
                    <img width={25} src={record?.typeIcon} />
                    <a className="text-underline" href=""><span className="text-blue">{record?.project}</span></a>
                </div>
            )}
        },
        {
            title: <div className='text-white text-center'>Total Staked</div>,
            dataIndex: 'totalStaked',
            key: 'totalStaked',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-blue text-center'>
                        {record?.totalStaked ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.totalStaked) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Total Reward</div>,
            dataIndex: 'totalReward',
            key: 'totalReward',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-white text-center'>
                        {record?.totalReward ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.totalReward) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Commission</div>,
            dataIndex: 'commission',
            key: 'commission',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-white text-center'>
                        {record?.commission ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.commission) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white'>Airdrop</div>,
            dataIndex: 'airdrop',
            key: 'airdrop',
            render: (text:any, record:any) => {
				
				return (
					<div className="flex-left">
                        <span className="text-blue">{new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.airdrop)}</span>
                        <div className="flex-left">
                            <img width={25} src={record?.typeIcon} />
                            <span className="text-white">$Hela</span>
                        </div>
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
						<button className="btn-blue-border"> Claim</button>
					</div>
				)
			}
        },
    ];
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="delegate-page">
                        <DelegateTop />
                        <div className="delegate-tabs mt-15">
                            <Tabs className="tabs-delegate" activeKey={activeTab} items={items} onChange={onChange} />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default Delegate;
