
import { Col, Tabs, Input, Layout, Row, Progress, Spin } from "antd";

import React, { useState, useRef, useEffect } from "react";
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import queryString from 'query-string';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";


const AuthRazer = () => {

    const { address } = useAccount();
    const [tabActive , setTabActive] = useState<any>('my-node');

    let code:any = '';
    let state:any = '';
    let paramUrl:any = queryString.parse(window.location.search);
    if (paramUrl.code !== undefined && paramUrl.code.length > 0 && paramUrl.state.length > 0) {
        code = paramUrl.code;
        state = paramUrl.state;
    }

    const onVerifyRazerId =async () => {
        let redirectUri:any = `${process.env.REACT_APP_BASE_URL}/auth-razer`;
        let data = {
            "redirectUri": redirectUri,
            "code": code
        }
        let res = await BaseApi.getTokenRazerId(data);
        console.log('res.data?.data', res.data?.data);

        if(res && res.status == 201 && res.data && res.data?.data?.access_token){
            localStorage.setItem('access_token', res.data?.data?.access_token);
            notiSuccess('Verify Successed.');
            setTimeout(() => {
                window.location.replace('/')
            }, 2000);
        }else{
            notiError('Verify Failed.');
            setTimeout(() => {
                window.location.replace('/')
            }, 1500);
        }
    }

    useEffect(() => {
        if(state && code){
            onVerifyRazerId();
        }

    }, [state, code]);
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="profile-banner text-center height-full">
                        <Spin size="large"/>
                        <p className="font-20 text-white weight-500">Waitting Verify...</p>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default AuthRazer;
