import React, { useState, useEffect } from "react";
import { Col, message, Row } from "antd";
import { Link } from "react-router-dom";

const Footer = () => {
  
  return (
    <>
      <div className="footer-main">
        <div className="container">
          <Row gutter={30}>
            
            <Col xs={24}>
              <p className="text-main text-center mt-15-sp">Copyright © 2024 HeLa</p>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};
export default Footer;
