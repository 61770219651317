
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { TabsProps } from 'antd';
import GuardianNodes from "./guardiant-node";
import MyNodes from "./my-node";
import MyNfts from "./my-nfts";


const DelegateTop = () => {

    let tab:any = localStorage.getItem('tabSlt');
    const [activeTab , setActiveTab] = useState<any>(tab ? tab : 'guardianNodes');
    // const [myNodes, setMyNodes]: any = useState([]);

    const onChange = (key: string) => {
        localStorage.setItem('tabSlt', key);
        setActiveTab(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'guardianNodes',
            label: 'Guardian Nodes',
            children: <GuardianNodes />,
        },
        {
            key: 'my-nodes',
            label: 'My Nodes',
            children: <MyNodes />,
        },
        {
            key: 'my-nfts',
            label: 'My NFTs',
            children: <MyNfts />,
        },
        
    ];

    const myNodes = [
        {
            key: '1',
            project: 'Hela NFTs',
            totalStaked: 2000000,
            totalReward: 1000,
            commision: 32,
            typeName: "Upswap",
            typeIcon: "../images/helalogo.svg",
            airdrop: 1000,
            status: true
        }
    ];

    const columnMyNode = [
        {
            title: <div className='text-white'>Project Name</div>,
            dataIndex: 'project',
            key: 'project',
            render: (text:any, record:any) => {    
            return (
                <div className="flex-left">
                    <img width={25} src={record?.typeIcon} />
                    <a className="text-underline" href={`node-detail/1`}><span className="text-blue">{record?.project}</span></a>
                </div>
            )}
        },
        {
            title: <div className='text-white text-center'>Total Staked</div>,
            dataIndex: 'totalStaked',
            key: 'totalStaked',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-blue text-center'>
                        {record?.totalStaked ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.totalStaked) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Total Reward</div>,
            dataIndex: 'totalReward',
            key: 'totalReward',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-white text-center'>
                        {record?.totalReward ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.totalReward) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Commission</div>,
            dataIndex: 'commission',
            key: 'commission',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-white text-center'>
                        {record?.commission ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 4}).format(record?.commission) : 0}
                    </div>
				)
			}
        },
        {
            title: <div className='text-white'>Airdrop</div>,
            dataIndex: 'airdrop',
            key: 'airdrop',
            render: (text:any, record:any) => {
				
				return (
					<div className="flex-left">
                        <span className="text-blue">{new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.airdrop)}</span>
                        <div className="flex-left">
                            <img width={25} src={record?.typeIcon} />
                            <span className="text-white">$Hela</span>
                        </div>
                    </div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
						<button className="btn-blue-border"> Claim</button>
					</div>
				)
			}
        },
    ];
    
  
    return (
        <>
            <div className="delegate-banner">
                <Row gutter={30}>
                    <Col xs={24} sm={4}>
                        <img className="img-full" src="../images/dele-banner-1.png" />
                    </Col>
                    <Col xs={24} sm={15}>
                        <div className="text-white weight-600 font-30 mt-30">THE FIRST AIRDROP</div>
                        <div className="text-white weight-500 font-25">250 <span className="text-green">$HELA</span></div>
                        <div className="mt-15">
                            <button className="btn-blue-border">Claim now</button>
                        </div>  
                    </Col>
                    <Col xs={24} sm={5}>
                        <img className="img-full flex-center" src="../images/dele-banner-3.png" />
                    </Col>
                </Row>
            </div>
            <div className="flex-btw mt-30">
                <div className="text-white weight-600 font-30">
                    Guardian Nodes
                </div>
                <div className="text-white">
                    <a href="">How to operate your own Verifier?</a>
                </div>
            </div>
            <div className="delegate-total mt-15">
                <Row gutter={30}>
                    <Col xs={12} sm={5}>
                        <div className="text-gray-light">License Key Holder</div>
                        <div className="text-white mt-10">0x5E90...0ec7</div>
                    </Col>
                    <Col xs={12} sm={5}>
                        <div className="text-gray-light">My number of NFTs</div>
                        <div className="text-white mt-10">0 (0 Delegated)</div>
                    </Col>
                    <Col xs={12} sm={5}>
                        <div className="text-gray-light">Status</div>
                        <div className="text-white mt-10">___</div>
                    </Col>
                    <Col xs={12} sm={5}>
                        <div className="text-gray-light">Total Rewards</div>
                        
                        <div className="d-flex-left mt-10">
                            <div className="text-white">
                                0.00 <span className="text-gray-light">HELA</span>
                            </div>
                            <button disabled className="btn-blue-border">Claim</button>
                        </div>
                    </Col>
                    <Col xs={12} sm={4} className="flex-center">
                        <button className="btn-blue-border">Delegated My License</button>
                    </Col>
                </Row>
            </div>
            <div className="mt-30">
                <div className="text-white font-20 weight-500">Projects</div>
                <div className="tab-over mt-15">
                    <Table scroll={{y: 400, x:700 }} className="table-projects" bordered={false} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'} dataSource={myNodes} columns={columnMyNode} pagination={false}/>
                </div>
            </div>
        </>
    );
};
export default DelegateTop;
