import React from "react";
import Countdown from "react-countdown";

const CountdownBlue = (countdownDate) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed){
      // window.location.reload();
    };
    return (
      <div className="coming-time">
        <div className="time-count flex-left">
          <div className="time-item text-darkblue-500">{days}<small>d</small></div> 
          <div className="time-item text-darkblue-500">{hours}<small>h</small></div> 
          <div className="time-item text-darkblue-500">{minutes}<small>m</small></div> 
          <div className="time-item text-darkblue-500">{seconds}<small>s</small></div>
        </div>
      </div>
    );
  };

  return <Countdown date={countdownDate} renderer={renderer} />;
};

export default CountdownBlue;
