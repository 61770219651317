import React, { useState, useEffect } from "react";
import { Dropdown, Modal } from "antd";
import {
    useAccount,
    useConnect,
    useDisconnect,
} from "wagmi";
import axios from "axios";
import {CaretRightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import { useNavigate } from "react-router-dom";
import "./style.css"
import { useWeb3Modal } from '@web3modal/wagmi/react'
import { useWeb3ModalState } from '@web3modal/wagmi/react'
import Web3 from "web3";


declare const window: Window & typeof globalThis & { ethereum: any; trustwallet: any; bitkeep: any; okxwallet: any; safepalProvider: any; safepal: any };

const WagmiConnect = (props: any) => {

    let closeDrawer = props.open
    const { open } = useWeb3Modal()
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId
    const { connect, connectors } = useConnect();
    const { address, connector, isConnected } = useAccount();
    let account = address
    const { disconnect } = useDisconnect();
    const [bnbBalance, setBnbBalance] = useState(0);
    const provider = "https://avalanche.public-rpc.com";
    let web3: any = "";
    if (typeof window !== "undefined") {
        web3 = window.ethereum ? new Web3(window.ethereum) : new Web3(provider);
    }

    const handleDisconnect = () => {
        disconnect();
        setIsModalVisible(false);
        localStorage.removeItem('signature');
        localStorage.removeItem('access_token');
        window.location.reload();
    };

    

    const [isModalVisible, setIsModalVisible] = useState<boolean>(false);

    const showModal = () => {
        setIsModalVisible(true);
    };

    const handleOk = () => {
        setIsModalVisible(false);
    };

    const handleCancel = () => {
        setIsModalVisible(false);
    };

    useEffect(() => {
        if (account) {
            web3.eth.getBalance(account, (err: any, balance: any) => {
                if (balance) {
                    let _balance: any = web3.utils.fromWei(
                        web3.utils.toBN(balance),
                        "ether"
                    );
                    let bnbBl = Math.round(parseFloat(_balance) * 10000) / 10000;
                    setBnbBalance(bnbBl);
                }
            });
        }
    }, [account]);

    

    const handleConnectWagmi = () => {
        open();
        // props.onClose(false);
        // window.location.reload();
      
    }

    const items = (
        <div className="modal-dis-new">
            <div className="top-dis">
                <div className="left-dis">
                    <div className="dis1">
                        <span>Wallet Connected</span>
                        <span className="dis1-add">
                            {account?.slice(0, 4)}...{account?.slice(-4)}
                        </span>
                    </div>
                </div>

                
            </div>
            
            <div className="top-dis3 d-flex-left" onClick={()=> window.location.replace('/profile')}>
                <CaretRightOutlined />
                <span>Profile</span>
            </div>
            <div className="mt-15 top-dis3 d-flex-left">
                <CaretRightOutlined />
                <button className="btn-disconnect" onClick={handleDisconnect}>
                    Disconnect
                </button>
            </div>

        </div>
    )

    return (
        <>
            {isConnected ? (
                <>
                    <Dropdown
                        trigger={['click']}
                        overlay={
                            items
                        }
                        placement="bottomRight"
                    >
                        <button className={`btn-lightblue border-black text-white`}>
                            <>
                               
                                {address?.substring(0, 4)}...{address?.substring(address.length - 4)}
                            </>
                        </button>
                    </Dropdown>
                </>
            ) : (
                <>
                    <button className="btn-lightblue border-black" onClick={handleConnectWagmi}>
                        Connect Wallet
                    </button>
                </>
            )}
            <Modal
                title={
                    <div className="text-md connect-wallet-title">
                        {!account ? (
                            <><img src="./images/connect-text.svg" alt="" /></>
                        ) : (
                            "Note"
                        )}
                    </div>
                }
                visible={isModalVisible}
                onOk={handleOk}
                footer={false}
                onCancel={handleCancel}
                className="modal-connect"
            >
                <div className="modal-content-connect">
                    {!isConnected ? (
                        <>
                            {connectors.map((connector:any) => (
                                <div
                                    className="btn-item-connect"
                                    // disabled={!connector.ready}
                                    key={connector.id}
                                    onClick={() => open()}
                                >
                                    {connector.name === "MetaMask" ? (
                                        <>
                                            <img src="./images/meta-mmm.png" alt="" />
                                        </>
                                    ) : (
                                        <>
                                            <img src="./images/wl.png" alt="" />
                                        </>
                                    )}
                                    <span className="name-chain-wl">{connector.name}</span>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            <button className="btn-connect" onClick={handleDisconnect}>
                                Disconnect
                            </button>
                        </>
                    )}
                </div>
            </Modal>
        </>
    );
};
export default WagmiConnect;


