
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { TabsProps } from 'antd';

import MyNft from "./my-nft";
import networkChange from "../../utils/networkChange";
import { useAccount } from "wagmi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";


const StakingNFT = () => {

    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState()
    const chainId: any = selectedNetworkId
    let tab:any = localStorage.getItem('tabSlt');
    const [activeTab , setActiveTab] = useState<any>(tab ? tab : 'guardian-nodes');

    const onChange = (key: string) => {
        localStorage.setItem('tabSlt', key);
        setActiveTab(key);
    };

    useEffect(() => {
        if (chainId && chainId !== 8668) {
            networkChange(8668);
        }
    }, [chainId]);

 
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="delegate-page">
                        
                        {/* <div className="flex-btw-mobile mt-30">
                            <div className="text-white weight-600 font-30">
                                NFT Staking Pool
                            </div>
                        </div> */}
                        {chainId && chainId !== 8668 && (
                            <div className="text-center">
                                <button className="btn-blue-border w-fitcontent bg-danger" onClick={()=> networkChange(8668)}>Incorrect network. Switch to HeLa.</button>
                            </div>
                        )}
                        
                        {/* <div className="delegate-total mt-15">
                            <Row gutter={30}>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light ">Start Time</div>
                                    <div className="text-blue mt-10 bg-blue p-5 w-fitcontent border-5">TBA</div>
                                </Col>
                                <Col xs={12} sm={5}>
                                    <div className="text-gray-light">End Time</div>
                                    <div className="text-blue mt-10 bg-blue p-5 w-fitcontent border-5">TBA</div>
                                </Col>
                                <Col xs={12} sm={5} className="mt-0-15-mb">
                                    <div className="text-gray-light">Total Staked</div>
                                    <div className="text-white mt-10">0 <span className="text-gray-light">HeLa NFT</span></div>
                                </Col>
                                
                                <Col xs={12} sm={5} className="mt-0-15-mb">
                                    <div className="text-gray-light">My Rewards</div>
                                    <div className="text-white mt-10">___</div>
                                </Col>
                                <Col xs={24} sm={4} className="flex-center mt-0-15-mb">
                                    <button className="btn-blue-border w-fitcontent" disabled>Claim <small className="text-white">(Coming soon)</small></button>
                                </Col>
                            </Row>
                        </div> */}
                        <div className="delegate-tabs mt-15">
                            <MyNft />
                        </div>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default StakingNFT;
