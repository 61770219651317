
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'



const GuardianNodes = () => {

    

    const dataSource = [
        {
            key: '1',
            address: '0xDF39246C45796ce93BA5041769Bf44EA5a0752f8',
            operator: 'Guardian Node 1',
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: true,
            received_delegations: 100,
        },
        {
            key: '2',
            address: '0xDF39246C45796ce93BA5041769Bf44EA5a0752f8',
            operator: 'Guardian Node 2',
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: true,
            received_delegations: 100,
        },
        {
            key: '3',
            address: '0xDF39246C45796ce93BA5041769Bf44EA5a0752f8',
            operator: 'Guardian Node 3',
            uptime_all: 32,
            uptime_7day: 32,
            commission: 32,
            status: false,
            received_delegations: 100,
        },
    ];

    const columns = [
        {
            title: 'Operator',
            dataIndex: 'operator',
            key: 'operator',
        },
        {
            title: 'Address',
            dataIndex: 'address',
            key: 'address',
            render: (text:any, record:any) => {
                    
            return (
                <div className="text-info">
                    {record?.address ? `${record?.address.substring(0, 4)}...${record?.address.substring(record?.address.length - 4)}` : "---"}
                
                </div>
            )}
        },
        {
            title: 'Received Delegations',
            dataIndex: 'received_delegations',
            key: 'received_delegations',
            render: (text:any, record:any) => {    
            return (
                <div className="text-info">
                    {new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.received_delegations)}
                </div>
            )}
        },
        {
            title: 'Uptime (All)',
            dataIndex: 'uptime_all',
            key: 'uptime_all',
        },
        {
            title: 'Uptime (7days)',
            dataIndex: 'uptime_7day',
            key: 'uptime_7day',
        },
        {
            title: 'Commission',
            dataIndex: 'commission',
            key: 'commission',
            render: (text:any, record:any) => {    
            return (
                <div className="text-info">
                    {new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.commission)}
                </div>
            )}
        },
        {
            title: <div className="text-center">Status</div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
                    
            return (
                <div className="text-center">
                    {record?.status == true ? (
                        <>
                            <div className="active-status margin-auto">Active</div>
                        </>
                    ):(
                        <>
                            <div className="deactive-status margin-auto">Deactive</div>
                        </>
                    )}
                </div>
            )}
        },
        {
            title: <div className='text-gray-light text-center'></div>,
            dataIndex: 'status',
            key: 'status',
            render: (text:any, record:any) => {
				
				return (
					<div className='text-center'>
                        
                        <button disabled={!record?.status} className="btn-white-border">Delegate</button>
                        
					</div>
				)
			}
        },
    ];
  
    return (
        <>
            <p className="text-gray-light mt-0">Total of <span className="text-white font-16">2533</span> Verifiers.</p>
            <div className="">
                <Table className="table-delegates" dataSource={dataSource} columns={columns} />
            </div>
        </>
    );
};
export default GuardianNodes;
