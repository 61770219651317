import React from "react";
import Countdown from "react-countdown";

const CountdownReloadComponent = (countdownDate) => {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    if (completed){
      // window.location.reload();
    };
    return (
      <div className="coming-time">
        <div className="time-count flex-left">
          <div className="time-item">{days}<small>d</small></div> 
          <div className="time-item">{hours}<small>h</small></div> 
          <div className="time-item">{minutes}<small>m</small></div> 
          <div className="time-item">{seconds}<small>s</small></div>
        </div>
      </div>
    );
  };

  return <Countdown date={countdownDate} renderer={renderer} />;
};

export default CountdownReloadComponent;
