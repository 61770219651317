
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import type { TabsProps } from 'antd';
import HeaderApp from "../../header-app";
import { Footer } from "antd/es/layout/layout";
import DelegateTop from "../delegate-top";



const NftsTab = () => {

    
    
    return (
        <>
            <div className="nfts-tab-content">
                asda
            </div>
        </>
    );
};
export default NftsTab;
