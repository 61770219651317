
import { Col, Tabs, Input, Layout, Row, Progress } from "antd";

import React, { useState, useRef, useEffect } from "react";
import type { TabsProps } from 'antd';
import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import MyNode from "./my-node";
import Refferals from "./referral";


const Profile = () => {

    const { address } = useAccount();
    let tabDefault:any = localStorage.getItem('activeTab') ? localStorage.getItem('activeTab') : 'my-node';
    const [tabActive , setTabActive] = useState<any>(tabDefault);
    const [totalNode , setTotalNode] = useState<any>(0);

    const onChange = (key: string) => {
        localStorage.setItem('activeTab', key);
        setTabActive(key);
    };

    const items: TabsProps['items'] = [
        {
            key: 'my-node',
            label: `My Nodes (${totalNode})`,
            children: <MyNode setTotalNode={setTotalNode}/>,
        },
        {
            key: 'referrals',
            label: 'Referrals',
            children: <Refferals />,
        }
    ];
  
  return (
    <>
        <div className="app-page">
            <HeaderApp />
            <div className="container">
                <div className="profile-banner">
                    <Row gutter={0}>
                        <Col xs={24} sm={4}>
                            <div className="profile-img">
                                <img src="../images/shield-node.svg" />
                            </div>
                        </Col>
                        <Col xs={24} sm={20}>
                            <div className="text-lightblue weight-600 font-24">
                                HeLa Guardian Node NFT
                            </div>
                            <div className="text-white weight-300 font-16 mt-15">
                                Empowering AI Infrastructure for Web2 and Web3 Applications
                            </div>
                            <div className="social mt-15 flex-left border-none">
                                <a href="https://discord.com/invite/helalabs" target="_blank"><img src="../images/dis-w.svg" /></a>
                                <a href="https://x.com/HeLa_Labs" target="_blank"><img src="../images/x-w.svg" /></a>
                                <a href="https://t.me/hela_official" target="_blank"><img src="../images/tele-x.svg" /></a>
                            </div>
                        </Col>
                    </Row>
                </div>
                <div className="profile-content">
                    <Tabs className="mt-20 tabs-custome" activeKey={tabActive} items={items} onChange={onChange} />
                </div>

            </div>
            <Footer />
        </div>
    </>
  );
};
export default Profile;
