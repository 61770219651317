
import { Col, message, Input, Layout, Row, Progress, Spin, Tag, Empty, Modal, Table } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {UnorderedListOutlined, AppstoreOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import Web3 from "web3";
import { AbiItem } from "web3-utils";
import { BaseApi } from "../../config/api/BaseApi";
import { useWeb3ModalState } from "@web3modal/wagmi/react";
import { useAccount } from "wagmi";
import { notiError, notiSuccess } from "../../utils/notication";
import { Loader } from "react-feather";
import queryString from 'query-string';
import { convertDateTime } from "../../utils/formatDate";
import WagmiConnect from "../../component/ConnectWallet/wagmi-connect";
import networkChange from "../../utils/networkChange";
import HelaAirdropAbi from '../../config/abi/HelaAirdropAbi.json'
import { convertFromWei } from "../../utils/convertNumber";

declare const window: Window &
  typeof globalThis & {
    trustwallet: any;
    ethereum: any;
    bitkeep: any;
    okexchain: any;
    safepalProvider: any;
};

const Airdrop = () => {

    const { address } = useAccount();
    const { selectedNetworkId } = useWeb3ModalState();
    const nodeDetailSection:any = useRef(null);
    const chainId: any = selectedNetworkId
    const [loadingBuy , setLoadingBuy] = useState<any>(false);
    const [setting, setSetting] = useState<any>(null);
    const [airdrops, setAirdrops]: any = useState<any>([]);
    const [userClaimed, setUserClaimed]: any = useState<any>(null);
    const [amountClaimed, setAmountClaimed]: any = useState<any>(0);

    let web3:any;
    const connectorId = window.localStorage.getItem("wagmi.recentConnectorId");
    

    if (connectorId === "io.metamask" || connectorId === "bsc") {
        web3 = new Web3(window.ethereum);
    } else if (connectorId === "com.trustwallet.app") {
        web3 = new Web3(window.trustwallet);
    } else if (connectorId === "com.bitget.web3") {
        web3 = new Web3(window.bitkeep.ethereum);
    } else if (connectorId === "com.okex.wallet") {
        web3 = new Web3(window.okexchain);
    } else {
        web3 = new Web3(window.ethereum);
    }

    let contractAddress:any = setting ? setting?.airdrop_address : '';

    let signatureMeta:any = localStorage.getItem('signature') ? localStorage.getItem('signature') : '';

    let airdropContract:any = '';
    if(contractAddress != ''){
        airdropContract = new web3.eth.Contract(
            HelaAirdropAbi as unknown as AbiItem,
            contractAddress
        );
    }

    const getSetting = async() => {
        try {
            const response = await BaseApi.getSetting();
            
            if(response.status == 200 && response.data && response.data?.data){
               setSetting(response.data?.data);
            }else{
                setSetting(null);
            }
        } catch (error) {
            console.log('error', error);
        }
    };
    useEffect(() => {
        getSetting();
    }, []);

    const getAirdrops = async () => {
        await BaseApi.getAirdrops(address).then(async (res)=>{
            if(res.status == 200 && res.data && res.data.data){
                let poolsData:any = res.data.data;
                let arrData:any = [];
                
                for (let i = 0; i < poolsData.length; i++) {
                    let item:any = poolsData[i];
                    let isClaimed = false;
                    if(airdropContract){
                        let resSc:any = await airdropContract.methods.airdrops(item?.id).call();
                        
                        if(Number(resSc?.amount) > 0){
                            isClaimed = true;
                        }
                    }

                    item.isClaimed = isClaimed;
                    
                    arrData.push(item);
                }
                setAirdrops(arrData);
            }
        }).catch((error)=>{
            console.log(error);
            setAirdrops([]);
        });
    }

    useEffect(() => {
        if(address && setting){
            getAirdrops();
        }
    }, [address, setting]);


    // const checkUserWl = async () => {
    //     await BaseApi.getUserAirdrop(address).then((res)=>{
    //         if(res.status == 200 && res.data && res.data.data){
    //             let poolsData:any = res.data.data;
    //             setUserAirdrop(poolsData);
    //         }
    //     }).catch((error)=>{
    //         console.log(error);
    //         setUserAirdrop(null);
    //     });
    // }

    // useEffect(() => {
    //     if(address){
    //         checkUserWl();
    //     }
    // }, [address]);

    

    // const checkUserClaimed = async () => {
    //     try {
            
    //         await airdropContract.methods.airdrops(userAirdrop?.id)
    //             .call()
    //             .then((res: any) => {
    //                 setUserClaimed(res);
    //                 let amountClaim = convertFromWei(res?.amount, 18);
    //                 setAmountClaimed(Number(amountClaim));
    //             })
    //             .catch((errAmountApprove: any) => {
    //                 console.log('err checkUserClaimed', errAmountApprove)
    //             })
    //     } catch (error) {
    //         console.log('error', error)
    //     }
    // }

    // useEffect(() => {
    //     if (userAirdrop && airdropContract != '') {
    //         checkUserClaimed();
    //     }
    // }, [userAirdrop, airdropContract])

    

    let nowDate = new Date();
    let startTime = setting?.airdrop_start_time ? new Date(setting?.airdrop_start_time) : '';

    const claimAirdrop = async (record:any)=>{
        setLoadingBuy(true);

        try {
            
            if (signatureMeta === '') {
                notiError('Sign invalid', 4)
                setLoadingBuy(false)
                return
            }
            if (airdropContract === '') {
                notiError('Contract invalid', 4)
                setLoadingBuy(false)
                return
            }
            let addressClaim:any = address;
            if (record?.user_address.toLowerCase !== addressClaim.toLowerCase) {
                notiError('User claim invalid', 4)
                setLoadingBuy(false)
                return
            }
  
            const gasPrice = await web3.eth.getGasPrice();

            let gasEstimate = await airdropContract.methods
                .claimAir(record?.id, record?.amount_in_wei, record?.signature)
                .estimateGas({
                    from: address,
                    gasPrice: web3.utils.toHex(String(gasPrice)),
                });

            await airdropContract.methods.claimAir(record?.id, record?.amount_in_wei, record?.signature)
            .send({
                from: address,
                gas: gasEstimate,
                gasPrice: web3.utils.toHex(String(gasPrice)),
            })
            .then(async (resStake: any) => {
                
                const interval = setInterval(function () {
                    web3.eth.getTransactionReceipt(
                        resStake?.transactionHash,
                        function (err: any, rec: any) {
                            if (rec) {
                                
                                clearInterval(interval);
                                notiSuccess('Successfully!', 5);
                                // checkUserClaimed();
                                getAirdrops();
                                setLoadingBuy(false);
                                
                            }
                            if (err) {
                                console.log("err", err);
                                setLoadingBuy(false)
                                notiError('Error Staking', 3);
                            }
                        }
                    )
                }, 1000);
            })
            .catch((errStake: any) => {
                console.log('errStake', errStake)
                notiError(errStake?.message || errStake, 3)
                setLoadingBuy(false);
            })
    
        } catch (error: any) {
            console.log('error', error?.message)
            notiError(error?.message || 'Unknown error occurred', 4)
            setLoadingBuy(false);
        }
    }

    const columns = [
        
        {
            title: <div className='text-white'>Name</div>,
            dataIndex: 'name',
            key: 'name',
            render: (text:any, record:any) => {
				
				return (
					<div className="d-flex-left text-blue">
                        <img src="../images/helanode.svg" /> 
                        <span>HeLa Airdrop</span>
                    </div>
				)
			}
        },
        {
            title: <div className='text-white'>Status</div>,
            dataIndex: 'total_node',
            key: 'total_node',
            render: (text:any, record:any) => {
				
				return (
					<>
                        {record?.isClaimed ? (
                            <>
                                <div className="claim-status claim-success">
                                    Claimed
                                </div>
                            </>
                        ):(
                            <>
                                <div className="claim-status claim-pending">
                                    Wating for claim
                                </div>
                            </>
                        )}
                    </>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Total Rewards</div>,
            dataIndex: 'amount',
            key: 'amount',
            render: (text:any, record:any) => {

				
				return (
					<div className='text-center'><span className="text-blue font-16">{record?.amount > 0 ? new Intl.NumberFormat("ja-JP", {maximumFractionDigits: 5}).format(record?.amount) : 0}</span> <span className="text-gray-light">HLUSD</span></div>
				)
			}
        },
        {
            title: <div className='text-white text-center'>Date</div>,
            dataIndex: 'amount',
            key: 'amount',
            render: (text:any, record:any) => {

				
				return (
					<div className='text-center'>{convertDateTime(new Date(record?.created_at))}</div>
				)
			}
        },
        {
            title: <div className='text-white text-center'></div>,
            dataIndex: 'amount',
            key: 'amount',
            render: (text:any, record:any) => {

				
				return (
					<>
                        <div className="text-center">
                            {chainId == 8668 ? (
                                <>
                                    {record?.isClaimed ? (
                                        <>
                                            <button className="btn-blue-border" disabled> Claimed</button>
                                        </>
                                    ):(
                                        <>
                                            <button className="btn-blue-border" disabled={loadingBuy} onClick={()=>claimAirdrop(record)}> Claim now {loadingBuy && <Spin size="small"/>}</button>
                                        </>
                                    )}
                                    
                                </>
                            ):(
                                <>
                                    {chainId ? (
                                        <>
                                            <button className="mt-10 btn-lightblue font-16 border-black" onClick={()=> networkChange(8668)}>
                                                Switch to HeLa
                                            </button>
                                        </>
                                    ):(
                                        <>
                                            <WagmiConnect />
                                        </>
                                    )}
                                    
                                </>
                            )}
                        </div>
                    </>
				)
			}
        },
    ];

    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="airdrop-page">
                        <div className="text-blue weight-500 font-24 text-center mt-15 mb-15">HeLa Airdrop</div>
                        <div className="airdrop-block">

                            <Table className="table-delegates" bordered={false} rowClassName={(record, index) => index % 2 === 0 ? 'table-row-light' :  'table-row-dark'} dataSource={airdrops} columns={columns} pagination={false}/>
                            
                        </div>
                    </div>
                </div>
                
            </div>
            <Footer />
            <Modal footer={false} className="modal-mint modal-salenode" title={<></>} open={loadingBuy}>
                <div className="text-center text-darkblue-500 font-24 mt-0 border-bottom-10 mb-15">HeLa Airdrop!</div>
                <p className="text-center text-gray-light">Please dont refresh the page meanwhile waiting for the transaction completed.</p>
                <div className="text-center mb-15">
                    <Spin size="large"/>
                </div>
            </Modal>
        </>
    );
};
export default Airdrop;
