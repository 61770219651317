
import { Col, Tabs, Input, Layout, Row, Progress, Divider, Collapse } from "antd";

import React, { useState, useRef, useEffect } from "react";

import {RightOutlined, InfoCircleOutlined} from '@ant-design/icons'
import HeaderApp from "../header-app";
import Footer from "../../component/common/Footer";
import type { CollapseProps } from 'antd';


const FAQ = () => {

    const items: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">What benefits do I get from buying a HeLa Guardian Node?</span>,
            children: <>
                <p className="mt-0 text-white">By running a HeLa Guardian Node, you directly contribute to the ecosystem by enhancing network security and supporting its growth. HeLa node owners will receive token rewards, including airdrops during listing events, and enjoy additional benefits from future collaborations with HeLa and its partners.</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">How do I get started and how can I set up my node?</span>,
            children: <>
                <p className="mt-0 text-white">Follow this guide for a step-by-step guide to help you get started: </p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">Where can I buy Node?</span>,
            children: <>
                <p className="mt-0 text-white">You can buy a HeLa Guardian Node through the HeLa Labs node sales page. Check it here: <a target="_blank" href="https://guardian.helalabs.com/">https://guardian.helalabs.com/</a></p>
            </>,
        },
        
        {
            key: '5',
            label: <span className="text-white weight-400">What unique features does the HeLa node offer compared to others?</span>,
            children: <>
                <ul className="text-white mt-0">
                    <li><strong>First Mover Advantage:</strong> Earn a part of HeLa's total supply.</li>
                    <li><strong>NFT License:</strong> Get an NFT for node ownership.</li>
                    <li><strong>Extra Earnings:</strong> Stake NFTs to earn tokens from partnered projects.</li>
                    <li><strong>One Node for All:</strong> Exclusive platform for staking future NFTs.</li>
                    <li><strong>Earn & Stake:</strong> Get more HeLa tokens for rewards or convert to stablecoins.</li>
                    <li><strong>Delegation Fees:</strong> Earn from others staking their NFTs to your node.</li>
                    <li><strong>Potential Airdrop Incentives:</strong> Potentially earn fees from future NFTs staked on the nodes.</li>
                </ul>
            </>,
        },
        {
            key: '6',
            label: <span className="text-white weight-400">Are we guaranteed rewards? </span>,
            children: <>
                <p className="mt-0 text-white">There is a fixed airdrop, but other perks depend on HeLa securing more partnerships. Please refer to the node deck for detailed ROI information for each tier.</p>
            </>,
        },
        {
            key: '7',
            label: <span className="text-white weight-400">How can owning a HeLa Guardian Node financially benefit me?</span>,
            children: <>
                <ul className="text-white mt-0">
                    <li><strong>Earn Rewards:</strong> Get HeLa Chain Rewards for network contribution.</li>
                    <li><strong>Early Access:</strong> Access new NFT launches for project tokens.</li>
                    <li><strong>Extra Earnings:</strong> Stake HeLa tokens for more rewards or convert to stablecoins.</li>
                    <li><strong>Delegation Fees:</strong> Earn from others staking their NFTs to your node.</li>
                    <li><strong>Airdrop Incentives:</strong> Earn fees from future NFTs staked on the nodes.</li>

                </ul>
            </>,
        },
    ];

    const itemsPricing: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">1. What is the initial price for a HeLa node?</span>,
            children: <>
                <p className="mt-0 text-white">The initial price is 330 HLUSD.</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">2. How many Guardian Nodes are available in each tier?</span>,
            children: <>
                <p className="mt-0 text-white">There are 2,500 Guardian Nodes available in each tier.</p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">3. What is the total number of Guardian Nodes available for sale?</span>,
            children: <>
                <p className="mt-0 text-white">A total of 50,000 Guardian Nodes are available for sale across 20 tiers.</p>
            </>,
        },
        {
            key: '4',
            label: <span className="text-white weight-400">4. Through which network will the node sale be conducted?</span>,
            children: <>
                <p className="mt-0 text-white">The sale will be conducted on HeLa network via a public sale. You will be able to use USDT/USDC/HLUSD on HeLa network to purchase the nodes. Currently, as of November 2024, we support HLUSD only. </p>
            </>,
        },
        {
            key: '5',
            label: <span className="text-white weight-400">5. Can you buy fractions of a node?</span>,
            children: <>
                <p className="mt-0 text-white">No, nodes will be sold in whole units only.</p>
            </>,
        },
        {
            key: '6',
            label: <span className="text-white weight-400">6. When can nodes be transferred after purchase?</span>,
            children: <>
                <p className="mt-0 text-white">Nodes can be transferred only after all the 50,000 nodes have been fully sold. </p>
            </>,
        },
        {
            key: '7',
            label: <span className="text-white weight-400">7. What is the difference per each tier? </span>,
            children: <>
                <p className="mt-0 text-white">Each tier has a different price and potential return. </p>
            </>,
        },
        {
            key: '8',
            label: <span className="text-white weight-400">8. What will I receive alongside the token node reward?</span>,
            children: <>
                <p className="mt-0 text-white">You will receive a total of 720 HeLa tokens per node vested over a period of 4 years. Besides HeLa tokens, node owners are on the priority list to receive perks from all partnerships and campaigns within the HeLa ecosystem.</p>
            </>,
        },
        {
            key: '9',
            label: <span className="text-white weight-400">9. What is the initial price of the Guardian Nodes?</span>,
            children: <>
                <p className="mt-0 text-white">The price is 330 HLUSD, equivalent to 330 USD (given that 1 HLUSD = 1 USDT/USDC). </p>
            </>,
        },
        {
            key: '10',
            label: <span className="text-white weight-400">10. How many token rewards does each node receive?</span>,
            children: <>
                <p className="mt-0 text-white">Each node receives 720 HeLa tokens rewards, vested over a period of 4 years.  </p>
            </>,
        },
        
    ];

    const itemsReferral: CollapseProps['items'] = [
        {
            key: '1',
            label: <span className="text-white weight-400">1. How and when will I receive my rebate and commission?</span>,
            children: <>
                <p className="mt-0 text-white">Your rebate and commission will be processed two weeks after the sale completion. Keep in mind, commissions might require passing KYC (Know Your Customer).</p>
            </>,
        },
        {
            key: '2',
            label: <span className="text-white weight-400">2. What if I want to refer to a lot of people?</span>,
            children: <>
                <p className="mt-0 text-white">If you have a large community or influence, you can apply for the Partner Referral Scheme and earn a commission ranging from 5% to 10%, depending on the amount sold.</p>
            </>,
        },
        {
            key: '3',
            label: <span className="text-white weight-400">3. Where do I apply for the Partner Referral Scheme?</span>,
            children: <>
                <p className="mt-0 text-white">You can apply for the Partner Referral Scheme using this link: Apply here. Generally, you will need to provide you Telegram ID, Email, Proof of Community Socials. </p>
            </>,
        },
        
    ];
    
  
    return (
        <>
            <div className="app-page">
                <HeaderApp />
                <div className="container">
                    <div className="faq-page">
                        <div className="text-center font-25 weight-500 text-white mb-15">FAQs about HeLa Node Sale</div>
                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Why purchase the HeLa Guardian Node?</div></Divider>
                        <Collapse items={items} expandIcon={({ isActive }) => ''} bordered={false}/>

                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Node Sale Pricing</div></Divider>
                        <Collapse items={itemsPricing} expandIcon={({ isActive }) => ''} bordered={false}/>

                        <Divider className="mb-0 text-center" orientation="left"><div className="text-lightblue font-20 weight-500 text-center">Referral</div></Divider>
                        <Collapse items={itemsReferral} expandIcon={({ isActive }) => ''} bordered={false}/>
                    </div>
                </div>
                <Footer />
            </div>
        </>
    );
};
export default FAQ;
